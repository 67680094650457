<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('sidebar.schools-list')"></ProfileNavbar>
    <h2 class="violations-title">
      {{ $t('my_results_1')}}
    </h2>
    <div class="tabs-headers">
      <button
          :class="{ active: activeTab == 'results' }"
          @click="activeTab = 'results'"
      >
        {{ $t('my_results_7')}}
      </button>
      <button
          :class="{ active: activeTab == 'activity' }"
          @click="activeTab = 'activity'"
      >
        {{ $t('my_results_8')}}
      </button>
    </div>
    <div v-if="activeTab == 'results'">
      <div class="results-wrapper">
        <h3 class="results-title">
          Тестирование первый этап олимпиады ”Мынбала”
        </h3>
        <div class="first-stage-list">
          <div class="first-stage-list-item blue-color">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#0055FF"/>
              <path d="M16.6672 9.33398L10.7295 16.4592C10.497 16.7383 10.3807 16.8778 10.3789 16.9956C10.3774 17.0981 10.423 17.1955 10.5027 17.2599C10.5944 17.334 10.776 17.334 11.1392 17.334H16.0006L15.3339 22.6673L21.2716 15.5421C21.5041 15.263 21.6204 15.1235 21.6222 15.0057C21.6237 14.9032 21.5781 14.8058 21.4984 14.7414C21.4067 14.6673 21.2251 14.6673 20.8619 14.6673H16.0006L16.6672 9.33398Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="first-stage-list-item-points blue-color">
              19/20
            </span>
            <span class="first-stage-list-item-subject blue-color">
              Логическое мышление
            </span>
          </div>
          <div class="first-stage-list-item lila-color">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.932617" width="32" height="32" rx="16" fill="#6C5DB9"/>
              <g clip-path="url(#clip0_2_2056)">
                <path d="M10.2666 17.6664L16.6948 20.8805C16.7822 20.9242 16.8259 20.9461 16.8718 20.9547C16.9124 20.9623 16.9541 20.9623 16.9947 20.9547C17.0406 20.9461 17.0843 20.9242 17.1718 20.8805L23.5999 17.6664M10.2666 14.3331L16.6948 11.119C16.7822 11.0752 16.8259 11.0534 16.8718 11.0448C16.9124 11.0372 16.9541 11.0372 16.9947 11.0448C17.0406 11.0534 17.0843 11.0752 17.1718 11.119L23.5999 14.3331L17.1718 17.5471C17.0843 17.5909 17.0406 17.6127 16.9947 17.6213C16.9541 17.6289 16.9124 17.6289 16.8718 17.6213C16.8259 17.6127 16.7822 17.5909 16.6948 17.5471L10.2666 14.3331Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_2_2056">
                  <rect width="16" height="16" fill="white" transform="translate(8.93262 8)"/>
                </clipPath>
              </defs>
            </svg>

            <span class="first-stage-list-item-points lila-color">
              5/20
            </span>
            <span class="first-stage-list-item-subject lila-color">
              Пространственное мышление
            </span>
          </div>
          <div class="first-stage-list-item red-color">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.865234" width="32" height="32" rx="16" fill="#FF2134"/>
              <g clip-path="url(#clip0_2_2048)">
                <path d="M10.1992 17.6664L16.6274 20.8805C16.7148 20.9242 16.7586 20.9461 16.8044 20.9547C16.845 20.9623 16.8867 20.9623 16.9274 20.9547C16.9732 20.9461 17.0169 20.9242 17.1044 20.8805L23.5326 17.6664M10.1992 14.3331L16.6274 11.119C16.7148 11.0752 16.7586 11.0534 16.8044 11.0448C16.845 11.0372 16.8867 11.0372 16.9274 11.0448C16.9732 11.0534 17.0169 11.0752 17.1044 11.119L23.5326 14.3331L17.1044 17.5471C17.0169 17.5909 16.9732 17.6127 16.9274 17.6213C16.8867 17.6289 16.845 17.6289 16.8044 17.6213C16.7586 17.6127 16.7148 17.5909 16.6274 17.5471L10.1992 14.3331Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_2_2048">
                  <rect width="16" height="16" fill="white" transform="translate(8.86523 8)"/>
                </clipPath>
              </defs>
            </svg>

            <span class="first-stage-list-item-points red-color">
              24/40
            </span>
            <span class="first-stage-list-item-subject red-color">
              Итоговый балл
            </span>
          </div>
        </div>
        <h3 class="results-title">
          Тестирование первый этап олимпиады ”Мынбала”
        </h3>
        <div class="first-stage-list">
          <div class="first-stage-list-item blue-color">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#0055FF"/>
              <path d="M16.6672 9.33398L10.7295 16.4592C10.497 16.7383 10.3807 16.8778 10.3789 16.9956C10.3774 17.0981 10.423 17.1955 10.5027 17.2599C10.5944 17.334 10.776 17.334 11.1392 17.334H16.0006L15.3339 22.6673L21.2716 15.5421C21.5041 15.263 21.6204 15.1235 21.6222 15.0057C21.6237 14.9032 21.5781 14.8058 21.4984 14.7414C21.4067 14.6673 21.2251 14.6673 20.8619 14.6673H16.0006L16.6672 9.33398Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="first-stage-list-item-points blue-color">
              19/20
            </span>
            <span class="first-stage-list-item-subject blue-color">
              Логическое мышление
            </span>
          </div>
          <div class="first-stage-list-item lila-color">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.932617" width="32" height="32" rx="16" fill="#6C5DB9"/>
              <g clip-path="url(#clip0_2_2056)">
                <path d="M10.2666 17.6664L16.6948 20.8805C16.7822 20.9242 16.8259 20.9461 16.8718 20.9547C16.9124 20.9623 16.9541 20.9623 16.9947 20.9547C17.0406 20.9461 17.0843 20.9242 17.1718 20.8805L23.5999 17.6664M10.2666 14.3331L16.6948 11.119C16.7822 11.0752 16.8259 11.0534 16.8718 11.0448C16.9124 11.0372 16.9541 11.0372 16.9947 11.0448C17.0406 11.0534 17.0843 11.0752 17.1718 11.119L23.5999 14.3331L17.1718 17.5471C17.0843 17.5909 17.0406 17.6127 16.9947 17.6213C16.9541 17.6289 16.9124 17.6289 16.8718 17.6213C16.8259 17.6127 16.7822 17.5909 16.6948 17.5471L10.2666 14.3331Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_2_2056">
                  <rect width="16" height="16" fill="white" transform="translate(8.93262 8)"/>
                </clipPath>
              </defs>
            </svg>

            <span class="first-stage-list-item-points lila-color">
              5/20
            </span>
            <span class="first-stage-list-item-subject lila-color">
              Пространственное мышление
            </span>
          </div>
          <div class="first-stage-list-item blue-color">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#0055FF"/>
              <path d="M16.6672 9.33398L10.7295 16.4592C10.497 16.7383 10.3807 16.8778 10.3789 16.9956C10.3774 17.0981 10.423 17.1955 10.5027 17.2599C10.5944 17.334 10.776 17.334 11.1392 17.334H16.0006L15.3339 22.6673L21.2716 15.5421C21.5041 15.263 21.6204 15.1235 21.6222 15.0057C21.6237 14.9032 21.5781 14.8058 21.4984 14.7414C21.4067 14.6673 21.2251 14.6673 20.8619 14.6673H16.0006L16.6672 9.33398Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="first-stage-list-item-points blue-color">
              19/20
            </span>
            <span class="first-stage-list-item-subject blue-color">
              Логическое мышление
            </span>
          </div>
          <div class="first-stage-list-item lila-color">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.932617" width="32" height="32" rx="16" fill="#6C5DB9"/>
              <g clip-path="url(#clip0_2_2056)">
                <path d="M10.2666 17.6664L16.6948 20.8805C16.7822 20.9242 16.8259 20.9461 16.8718 20.9547C16.9124 20.9623 16.9541 20.9623 16.9947 20.9547C17.0406 20.9461 17.0843 20.9242 17.1718 20.8805L23.5999 17.6664M10.2666 14.3331L16.6948 11.119C16.7822 11.0752 16.8259 11.0534 16.8718 11.0448C16.9124 11.0372 16.9541 11.0372 16.9947 11.0448C17.0406 11.0534 17.0843 11.0752 17.1718 11.119L23.5999 14.3331L17.1718 17.5471C17.0843 17.5909 17.0406 17.6127 16.9947 17.6213C16.9541 17.6289 16.9124 17.6289 16.8718 17.6213C16.8259 17.6127 16.7822 17.5909 16.6948 17.5471L10.2666 14.3331Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_2_2056">
                  <rect width="16" height="16" fill="white" transform="translate(8.93262 8)"/>
                </clipPath>
              </defs>
            </svg>

            <span class="first-stage-list-item-points lila-color">
              5/20
            </span>
            <span class="first-stage-list-item-subject lila-color">
              Пространственное мышление
            </span>
          </div>
          <div class="first-stage-list-item red-color">
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.865234" width="32" height="32" rx="16" fill="#FF2134"/>
              <g clip-path="url(#clip0_2_2048)">
                <path d="M10.1992 17.6664L16.6274 20.8805C16.7148 20.9242 16.7586 20.9461 16.8044 20.9547C16.845 20.9623 16.8867 20.9623 16.9274 20.9547C16.9732 20.9461 17.0169 20.9242 17.1044 20.8805L23.5326 17.6664M10.1992 14.3331L16.6274 11.119C16.7148 11.0752 16.7586 11.0534 16.8044 11.0448C16.845 11.0372 16.8867 11.0372 16.9274 11.0448C16.9732 11.0534 17.0169 11.0752 17.1044 11.119L23.5326 14.3331L17.1044 17.5471C17.0169 17.5909 16.9732 17.6127 16.9274 17.6213C16.8867 17.6289 16.845 17.6289 16.8044 17.6213C16.7586 17.6127 16.7148 17.5909 16.6274 17.5471L10.1992 14.3331Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_2_2048">
                  <rect width="16" height="16" fill="white" transform="translate(8.86523 8)"/>
                </clipPath>
              </defs>
            </svg>

            <span class="first-stage-list-item-points red-color">
              24/40
            </span>
            <span class="first-stage-list-item-subject red-color">
              Итоговый балл
            </span>
          </div>
        </div>
        <h3 class="results-title">
          Сертификаты
        </h3>
        <div class="serts-list">
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
          <div class="sert-item">
            <a class="sert-item-link">Скачать PDF файл</a>
          </div>
        </div>
      </div>

    </div>
    <div v-if="activeTab == 'activity'">
      <div
          v-for="(attempt, index) in attempts"
          :key="attempt.id"
          class="attempt-block"
      >
        <h3 v-if="$i18n.locale === 'ru'" class="violations-subtitle">Попытка прохождения тестирования №{{ index + 1 }}</h3>
        <h3 v-if="$i18n.locale === 'kz'" class="violations-subtitle">№{{ index + 1 }} тестілеуден өту әрекеті</h3>
        <div class="violations-table-wrapper">
          <table class="violations-table">
            <thead>
            <tr>
              <th>№</th>
              <th>{{ $t('my_results_2')}}</th>
              <th>{{ $t('my_results_3')}}</th>
              <th>{{ $t('my_results_4')}}</th>
              <th>{{ $t('my_results_5')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="attempt.violations.length === 0">
              <td colspan="5">{{ $t('my_results_6')}}</td>
            </tr>
            <tr
                v-for="(violation, vIndex) in attempt.violations"
                :key="violation.id"
            >
              <td>{{ vIndex + 1 }}</td>
              <td>{{ $t(`${violation.type}`) }}</td>
              <td>{{ formattedDate(violation.created_at) }}</td>
              <td>
                <span v-if="violation.type == 'noise_level'">
                <svg width="48" height="38" viewBox="0 0 69 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
                  <rect x="7.7207" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect x="7.7207" y="38.6992" width="6.12467" height="14.0386" rx="3.06234" fill="#E24C4B"/>
                  <rect x="15.4414" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect x="62" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect x="15.4414" y="12.7676" width="6.12467" height="39.9707" rx="3.06234" fill="#E24C4B"/>
                  <rect x="62" y="12.7676" width="6.12467" height="39.9707" rx="3.06234" fill="#E24C4B"/>
                  <rect x="23.1602" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect x="23.1602" y="24.6758" width="6.12467" height="28.0631" rx="3.06234" fill="#E24C4B"/>
                  <rect x="30.7012" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect x="30.7012" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
                  <rect x="38.5098" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect x="38.5098" y="28.1152" width="6.12467" height="24.6231" rx="3.06234" fill="#E24C4B"/>
                  <rect x="46.1426" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect x="54" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
                  <rect x="46.1426" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
                  <rect x="54" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
                </svg>
                  </span>
                <span v-if="violation.type == 'no_face'">
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
<path d="M25.5 26C27.433 26 29 24.433 29 22.5C29 20.567 27.433 19 25.5 19C23.567 19 22 20.567 22 22.5C22 24.433 23.567 26 25.5 26Z" stroke="#FF5257" stroke-width="2" stroke-linejoin="round"/>
<path d="M32.5 31.5L36.5 35.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.5 35.5L36.5 31.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.5 30H25.4C23.1598 30 22.0397 30 21.1841 30.436C20.4314 30.8195 19.8195 31.4314 19.436 32.184C19 33.0397 19 34.1598 19 36.4V37H29.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </span>
                <span v-if="violation.type == 'multiple_faces'">
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
<path d="M28 26C29.933 26 31.5 24.433 31.5 22.5C31.5 20.567 29.933 19 28 19C26.067 19 24.5 20.567 24.5 22.5C24.5 24.433 26.067 26 28 26Z" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 19.627C21.0932 20.2594 20.5 21.3102 20.5 22.4996C20.5 23.7728 21.1798 24.8872 22.1962 25.4996" stroke="#FF5257" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34 19.627C34.9068 20.2594 35.5 21.3102 35.5 22.4996C35.5 23.689 34.9068 24.7398 34 25.3723" stroke="#FF5257" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 36V37H34V36C34 34.1363 34 33.2043 33.6955 32.4692C33.2895 31.4891 32.5109 30.7104 31.5308 30.3045C30.7957 30 29.8637 30 28 30C26.1363 30 25.2043 30 24.4692 30.3045C23.4891 30.7104 22.7105 31.4891 22.3045 32.4692C22 33.2043 22 34.1363 22 36Z" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38.0005 36.9996V36.3996C38.0005 34.1594 38.0005 33.0393 37.5645 32.1836C37.181 31.431 36.5691 30.819 35.8164 30.4355" stroke="#FF5257" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 36.9996V36.3996C18 34.1594 18 33.0393 18.436 32.1836C18.8195 31.431 19.4314 30.819 20.184 30.4355" stroke="#FF5257" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </span>
                <span v-if="violation.type == 'tab_changed'">
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
<path d="M28 37.7504C22.6239 37.7504 18.25 33.369 18.25 27.984C18.25 25.0566 19.5273 22.3121 21.7544 20.4582C21.8584 20.3711 21.9785 20.3054 22.1079 20.2648C22.2373 20.2242 22.3735 20.2095 22.5086 20.2217C22.6437 20.2338 22.775 20.2724 22.8952 20.3354C23.0153 20.3983 23.1219 20.4844 23.2087 20.5885C23.2955 20.6927 23.361 20.813 23.4012 20.9426C23.4415 21.0721 23.4558 21.2083 23.4434 21.3433C23.431 21.4784 23.392 21.6097 23.3288 21.7296C23.2655 21.8496 23.1792 21.956 23.0748 22.0426C21.3194 23.5051 20.3125 25.6707 20.3125 27.984C20.3125 32.2318 23.7611 35.6879 28 35.6879C32.2389 35.6879 35.6875 32.2318 35.6875 27.984C35.6874 26.8487 35.4358 25.7276 34.9509 24.7011C34.466 23.6747 33.7598 22.7683 32.883 22.0472C32.7744 21.9624 32.6838 21.8568 32.6168 21.7364C32.5497 21.616 32.5074 21.4835 32.4924 21.3465C32.4775 21.2095 32.4901 21.0709 32.5296 20.9389C32.569 20.8069 32.6346 20.6841 32.7223 20.5779C32.81 20.4716 32.9181 20.384 33.0403 20.3202C33.1624 20.2565 33.2961 20.2178 33.4334 20.2066C33.5707 20.1953 33.7089 20.2117 33.8398 20.2548C33.9707 20.2978 34.0916 20.3667 34.1955 20.4572C35.307 21.3715 36.2023 22.5206 36.8169 23.822C37.4314 25.1234 37.7501 26.5447 37.75 27.984C37.75 33.369 33.3761 37.7504 28 37.7504Z" fill="#FF5257"/>
<path d="M28 28.75C27.7265 28.75 27.4642 28.6414 27.2708 28.448C27.0774 28.2546 26.9688 27.9923 26.9688 27.7188V19.2812C26.9688 19.0077 27.0774 18.7454 27.2708 18.552C27.4642 18.3586 27.7265 18.25 28 18.25C28.2735 18.25 28.5358 18.3586 28.7292 18.552C28.9226 18.7454 29.0312 19.0077 29.0312 19.2812V27.7188C29.0312 27.9923 28.9226 28.2546 28.7292 28.448C28.5358 28.6414 28.2735 28.75 28 28.75Z" fill="#FF5257"/>
</svg>

                </span>

<!--                <span v-if="violation.violationStatus === 'ok'">-->
<!--                ✓-->
<!--              </span>-->
<!--                <span v-else>-->
<!--                ⚠-->
<!--              </span>-->
              </td>
              <td>
              <span v-if="violation.file">
<!--                <img width="100" height="60" :src="backImg + violation.file" alt="Фото нарушения">-->
                <a :href="backImg + violation.file" target="_blank" class="photo-link btn-primary">
                  Фото
                </a>
              </span>
                <span v-else>Пусто</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Loader v-if="loader"/>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import ProfileNavbar from '@/components/ProfileNavbar'
import {VueperSlides, VueperSlide} from 'vueperslides'
import VueMask from 'v-mask'
import 'vueperslides/dist/vueperslides.css'
import {required, email} from 'vuelidate/lib/validators'
import Loader from '@/components/Loader'

export default {
  name: 'Results',
  components: {
    Loader,
    ProfileNavbar,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      modalInfo: {},
      tableData: [],
      errorMessage: '',
      attempts: [],
      loader: true,
      backImg: 'https://object.pscloud.io/lithium-60/',
      activeTab: 'results'
    }
  },
  methods: {
    openModal(item) {
      this.modalInfo = item
      this.$bvModal.show('results')
    },
    formattedDate(data) {
      const date = new Date(data)
      const hours = String(date.getUTCHours()).padStart(2, '0')
      const minutes = String(date.getUTCMinutes()).padStart(2, '0')
      const day = String(date.getUTCDate()).padStart(2, '0')
      const month = String(date.getUTCMonth() + 1).padStart(2, '0')
      const year = date.getUTCFullYear()

      return `${hours}:${minutes} ${day}.${month}.${year}`
    }
  },
  beforeCreate() {
    Vue.use(VueMask)
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/quiz/proctoring')
        .then(response => {
          this.attempts = response.body.data.map(attempt => ({
            ...attempt,
            violations: []
          }));

          this.attempts.forEach((attempt, index) => {
            this.$http.get(window.API_ROOT + '/api/quiz/proctoring/' + attempt.id)
                .then(resp => {
                  this.$set(this.attempts[index], 'violations', resp.body.data.filter( item => item.type != 'planned'));
                })
                .catch(error => {
                  console.error(`Ошибка загрузки нарушений для попытки id=${attempt.id}`, error);
                })

          });
        })
        .catch(error => {
          console.error('Ошибка загрузки попыток', error);
        })
        .finally(() => {
          this.loader = false
        });

  }
}
</script>
<style scoped>
.simple-table {
  width: 100%;
  border-collapse: collapse;
}

.simple-table th, .simple-table td {
  padding: 8px;
  text-align: left;
}
</style>

